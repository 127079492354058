import styled from "styled-components";
import TripStatus from "./TripStatus";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useState } from "react";
import CustomMap from "./CustomMap";

const ActiveTrip = ({ trip, getTrip, getOngoingRefresh }) => {
	const [isVisible, setIsVisible] = useState(false);

	const handleToggle = () => {
		setIsVisible((isVisible) => !isVisible);
	};

	return (
		<Container active={trip?.active}>
			<Head>
				<div className={"id"}>
					<div>
						{trip?.container_number && (
							<p>
								Container ID: <span>{trip?.container_number}</span>
							</p>
						)}
						{trip?.tracking_id && (
							<p>
								Tracking ID: <span>{trip?.tracking_id}</span>
							</p>
						)}
					</div>
					<Status>
						<TripStatus status={trip?.state?.value} />
					</Status>
				</div>
				<div className={"view-details"} onClick={() => getTrip(trip._id)}>
					<span>View Details</span>
					<AiOutlineArrowRight />
				</div>
			</Head>
			<Address>
				<img src={"/paymentcard.png"} alt={"icon"} />
				<p>{trip?.start_address}</p>
			</Address>
			<Address>
				<img src={"/paymentcard.png"} alt={"icon"} />
				<p>{trip?.end_address}</p>
			</Address>
			<Toggle>
				<p onClick={handleToggle}>{isVisible ? "Close Map" : "Open Map"}</p>
			</Toggle>
			{isVisible && (
				<div className={"map-container"} style={{ width: "100%" }}>
					<CustomMap
						width={90}
						ongoing
						active_height={"300px"}
						data={trip}
						locations={{
							start: trip?.start_location?.coordinates,
							end: trip?.end_location?.coordinates,
						}}
						refreshTripData={getOngoingRefresh}
					/>
				</div>
			)}
		</Container>
	);
};

const Container = styled.div`
	cursor: pointer;
	width: 90%;
	margin: 20px auto 30px;
	background: ${({ active }) => (active ? "#fff9f0" : "white")};
	padding: 20px;
	border-radius: 10px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

	.map-container {
		display: none;
	}

	@media (max-width: 850px) {
		padding-bottom: 0;
		overflow: hidden;

		.map-container {
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	margin-bottom: 20px;

	span {
		margin-right: 20px;
	}

	.id {
		display: flex;
		gap: 12px;

		p {
			margin: 0;
			padding-bottom: 4px;
		}

		span {
			font-weight: 600;
		}
	}

	.view-details {
		display: flex;
		align-items: center;
		color: ${({ theme }) => theme.colors.primary};
		font-size: 13px;
	}

	.view-details span {
		margin-right: 5px;
	}

	@media (max-width: 400px) {
		font-size: 12px;

		span {
			margin-right: 5px;
		}

		.view-details {
			font-size: 12px;
		}
		.view-details span {
			.view-details span {
				margin-right: 1px;
			}
		}
	}
`;

const Address = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	img {
		width: 40px;
		height: 40px;
		margin-right: 20px;
	}

	p {
		margin: 0;
		font-size: 15px;
	}

	@media (max-width: 400px) {
		p {
			font-size: 12px;
		}
	}
`;

const Status = styled.div`
	@media (max-width: 450px) {
		display: none;
	}
`;

const Toggle = styled.div`
	display: none;

	p {
		color: ${({ theme }) => theme.colors.primary};
		cursor: pointer;
	}

	@media (max-width: 850px) {
		display: flex;
		justify-content: flex-end;
	}
`;

export default ActiveTrip;
