import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { Button } from "antd";
import CustomMap from "../../components/common/CustomMap";
import Index from "../../components/common/RequestTripModal";
import RequirementFormModal from "../../components/common/RequirementFormModal";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import SearchingDriverModal from "../../components/common/SearchingDriverModal";
import VerificationModal from "../../components/common/VerificationModal";
import VerifiedModal from "../../components/common/VerifiedModal";
import CardModal from "../../components/common/CardModal";
import ProfileInfoModal from "../../components/common/ProfileInfoModal";
import LicenseModal from "../../components/common/LicenseModal";
import TerminalList from "../../components/TerminalList";
import { TRIP_TYPE } from "../../constants/trip";
import PlaceBidModal from "../../components/bidding/PlaceBidModal";
import usePageTitle from "../../components/hooks/usePageTitle";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UnpaidInvoiceModal from "../../components/common/UnpaidInvoiceModal";
import ReviewTripModal from "../../components/common/ReviewTripModal";

const RequestTrip = (props) => {
	const [user, setUser] = useState(props?.user);
	const [cards, setCards] = useState(props?.card_list);
	const [verificationVisible, setVerificationVisible] = useState(false);
	const [unpaidInvoiceVisible, setUnpaidInvoiceVisible] = useState(false);
	const [verifiedVisible, setVerifiedVisible] = useState(false);
	const [visiblePayment, setVisiblePayment] = useState(false);
	const [visibleProfile, setVisibleProfile] = useState(false);
	const [visibleLicense, setVisibleLicense] = useState(false);
	const [visibleTerminalList, setVisibleTerminalList] = useState(false);
	const [terminalOpener, setTerminalOpener] = useState("");

	const [triggerNext, setTriggerNext] = useState(false);

	const [currentStep, setCurrentStep] = useState(0);
	const [openBidModal, setOpenBidModal] = useState(false);
	const [visible, setVisible] = useState(false);
	const [visible2, setVisible2] = useState(false);
	const [visible3, setVisible3] = useState(false);
	const [refreshed, setRefreshed] = useState(false);
	const [searchingDriver, setSearchingDriver] = useState(false);
	const [reviewTrip, setReviewTrip] = useState(false);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const id = searchParams.get("id");
	const isDraft = searchParams.get("is_draft");

	const requestRef = useRef(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (id) {
			requestRef.current?.click();
		}
	}, [id]);

	const [data, setData] = useState({
		trip_category: "TERMINAL_LOCATION",
		item_size: "",
		cargo_type: "CONTAINER",
		offloading_duration: "",
		is_container_owner: false,
		acceptBid: false,
		bid_price: "",
	});
	const [startDate, setStartDate] = useState("");
	const [tdoExpiryDate, setTdoExpiryDate] = useState(new Date());
	const [startAddress, setStartAddress] = useState("");
	const [endAddress, setEndAddress] = useState("");
	const [dropOffAddress, setDropOffAddress] = useState("");
	const [imageUrl, setImageUrl] = useState(null);
	const [cargoImage, setCargoImage] = useState(null);
	const [costId, setCostId] = useState("");

	const [startCoordinates, setStartCoordinates] = useState({ lat: null, lng: null });
	const [endCoordinates, setEndCoordinates] = useState({ lat: null, lng: null });
	const [dropOffCoordinates, setDropOffCoordinates] = useState({ lat: null, lng: null });
	const [estimatedPrice, setEstimatedPrice] = useState("");
	const [estimatedUpperPrice, setEstimatedUpperPrice] = useState("");

	usePageTitle("Request Trip");

	const closeModal = () => {
		setVisible(false);
	};

	const closeModal2 = () => {
		setVisible2(false);
	};

	const getUser = async () => {
		try {
			const response = await props.getMe();
			setUser(response?.payload?.data?.user);
			// console.log(response?.payload?.data?.user);
			if (response?.error) {
				throw new Error(response);
			}
		} catch (e) {
			// console.log(e, 'error');
			return e;
		}
	};

	const getCards = async () => {
		try {
			const res = await props.getCardList();
			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
			setCards(res?.payload?.data?.cards);
		} catch (e) {
			return e;
		}
	};

	useEffect(() => {
		getUser();
		getCards();
	}, [triggerNext]);

	useEffect(() => {
		if (user?.is_email_or_phone_verified === false) {
			setVerificationVisible(true);
			return;
		}
		// else if (
		// 	user?.is_license_agreed_to === false &&
		// 	cards?.length === 0 &&
		// 	user?.payment_plan !== "offline"
		// ) {
		// 	setVisiblePayment(true);
		// }
		else if (
			user?.is_license_agreed_to === false
			// && cards?.length > 0
		) {
			setVisibleProfile(true);
		}
	}, [user]);

	const handleCard = () => {
		setVisiblePayment(false);
		setVisibleProfile(true);
	};

	const goBackToAddCard = () => {
		setVisibleProfile(false);
		setVisiblePayment(true);
	};

	const profileNext = () => {
		setVisibleProfile(false);
		setVisibleLicense(true);
	};

	useEffect(() => {
		const is_accepted = localStorage.getItem("created_trip_is_accepted");
		const trip_id = localStorage.getItem("created_trip_id");

		if (is_accepted === "false") {
			setVisible3(true);
			setSearchingDriver(true);
			const checkIfAcceptedAtInterval = setInterval(() => {
				props.getTrip(trip_id).then((res) => {
					const today = moment(new Date());
					const startTime = moment(res?.payload?.data?.trip?.start_time);
					const minutesBeforeStartTime = startTime.diff(today, "minutes");

					if (minutesBeforeStartTime < 0) {
						toast.error(
							(t) => <span>Could not find a driver before start time.</span>,
							{},
						);
						setVisible3(false);
						localStorage.setItem("created_trip_is_accepted", "ended");
						setSearchingDriver(false);
						clearInterval(checkIfAcceptedAtInterval);
						return;
					}

					if (res?.payload?.data?.trip?.is_accepted === true) {
						toast.success((t) => <span>Trip accepted.</span>, {});
						setVisible3(false);
						localStorage.setItem("created_trip_is_accepted", "ended");
						setSearchingDriver(false);
						clearInterval(checkIfAcceptedAtInterval);
						return;
					}
				});
			}, 30000);
		}
	}, [refreshed]);

	const { acceptBid, _id, socket_id, ...rest } = data;
	const tripData = {
		...rest,
		trip_category:
			data.cargo_type === "CONTAINER" ? data.trip_category : TRIP_TYPE.LOCATION_LOCATION,
		is_container_owner: data?.cargo_type === "LOOSE_GOODS" ? true : data?.is_container_owner,
		offloading_duration: 86400000 * +data.offloading_duration,
		...(costId ? { cost_id: costId } : {}),
		drop_off_address: dropOffAddress,
		drop_off_lng: dropOffCoordinates?.lng,
		drop_off_lat: dropOffCoordinates?.lat,
		tdo_expiry_date: tdoExpiryDate,
		tdo_image: imageUrl,
		cargo_image: cargoImage,
		cargo_category: data.cargo_category,
		cargo_value: data.cargo_value,
		cargo_description: data.cargo_description,
		customer_comment: data.customer_comment,
		end_address: endAddress,
		end_lng: endCoordinates?.lng,
		end_lat: endCoordinates?.lat,
		start_address: startAddress,
		start_lng: startCoordinates?.lng,
		start_lat: startCoordinates?.lat,
		start_time: startDate,
	};

	if (!data.acceptBid) {
		delete tripData.bid_price;
	}

	const handleCreateTrip = async () => {
		try {
			const res = isDraft
				? await props.updateDraft({ id, data: { ...tripData, is_draft: false } })
				: await props.createTrip({ ...tripData, is_draft: false });

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
			localStorage.setItem("created_trip_is_accepted", res?.payload?.data?.trip?.is_accepted);
			localStorage.setItem("created_trip_id", res?.payload?.data?.trip?._id);
			const today = moment(new Date());
			const startTime = moment(res?.payload?.data?.trip?.start_time);
			const minutesBeforeStartTime = startTime.diff(today, "minutes");
			if (minutesBeforeStartTime >= 15) {
				toast.success(
					(t) => <span>Successfully created a trip. Trip scheduled for later time.</span>,
					{},
				);
				setVisible2(false);
				localStorage.setItem("created_trip_is_accepted", "ended");
				if (data?.acceptBid) navigate("/app/pending-bids", { replace: true });
				return;
			}
			if (minutesBeforeStartTime < 0) {
				toast.error((t) => <span>Could not find a driver before start time.</span>, {});
				setVisible2(false);
				localStorage.setItem("created_trip_is_accepted", "ended");
				return;
			} else {
				setRefreshed((refreshed) => !refreshed);
				setVisible2(false);
				toast.success((t) => <span>Successfully created a trip!</span>, {});
			}
		} catch (e) {
			if (e.message === "No valid card") setVisiblePayment(true);
			toast.error(
				`${
					(e.message === "No valid card"
						? "Please add a card before requesting for trip"
						: e.message) || "Error creating a trip"
				}`,
			);
			return e;
		}
	};

	const saveDraft = async () => {
		try {
			const res = isDraft
				? await props.updateDraft({ id, data: tripData })
				: await props.saveToDraft(tripData);

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
			toast.success("Trip saved to draft successfully");
			navigate("/app/trips/drafts");
		} catch (e) {
			toast.error(e.message || "An error occured");
		}
	};

	const startRequest = () => {
		setVisible(true);
		setCurrentStep(0);
		setData({
			trip_category: "TERMINAL_LOCATION",
			item_size: "",
			cargo_type: "CONTAINER",
			is_container_owner: false,
			acceptBid: false,
			bid_price: "",
		});
		setStartDate("");
		setTdoExpiryDate(new Date());
		setStartAddress(" ");
		setEndAddress(" ");
		setDropOffAddress(" ");
		setImageUrl(null);
		setCostId("");

		setStartCoordinates({ lat: null, lng: null });
		setEndCoordinates({ lat: null, lng: null });
		setDropOffCoordinates({ lat: null, lng: null });
		setEstimatedPrice("");
		setEstimatedUpperPrice("");
	};

	const getUnpaidTrips = async () => {
		try {
			const res = await props.getUnpaidTrips();
			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
		} catch (e) {
			return e;
		}
	};
	const fetchUnratedTrip = async () => {
		try {
			const res = await props.fetchUnratedTrip();
			if (res?.payload?.data?.trip) openReviewTripModal();

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
		} catch (e) {
			return e;
		}
	};

	const defferTripRating = async () => {
		try {
			const res = await props.updateTrip({
				id: props.unrated_trip._id,
				data: { rating_deferred_at: new Date() },
			});

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
		} catch (e) {
			return e;
		}
	};

	const openReviewTripModal = () => setReviewTrip(true);
	const closeReviewTripModal = () => {
		defferTripRating();
		setReviewTrip(false);
	};

	useEffect(() => {
		getUnpaidTrips();
		fetchUnratedTrip();
	}, []);
	useEffect(() => {
		if (props.unpaid_trips?.length >= 3) {
			setUnpaidInvoiceVisible(true);
		} else {
			setUnpaidInvoiceVisible(false);
		}
	}, [props.unpaid_trips]);

	return (
		<Container>
			<CustomMap width={100} locations={{ start: [], end: [] }} disableFullScreen />
			<ButtonContainer>
				<Button ref={requestRef} type={"primary"} onClick={startRequest}>
					Request Trip
				</Button>
			</ButtonContainer>
			<Index
				visible={visible}
				closeModal={closeModal}
				setVisible={setVisible}
				setVisible2={setVisible2}
				data={data}
				setData={setData}
				startDate={startDate}
				setStartDate={setStartDate}
				tdoExpiryDate={tdoExpiryDate}
				setTdoExpiryDate={setTdoExpiryDate}
				startAddress={startAddress}
				setStartAddress={setStartAddress}
				endAddress={endAddress}
				setEndAddress={setEndAddress}
				dropOffAddress={dropOffAddress}
				setDropOffAddress={setDropOffAddress}
				imageUrl={imageUrl}
				setImageUrl={setImageUrl}
				cargoImage={cargoImage}
				setCargoImage={setCargoImage}
				estimatedPrice={estimatedPrice}
				setEstimatedPrice={setEstimatedPrice}
				costId={costId}
				setCostId={setCostId}
				startCoordinates={startCoordinates}
				setStartCoordinates={setStartCoordinates}
				endCoordinates={endCoordinates}
				setEndCoordinates={setEndCoordinates}
				dropOffCoordinates={dropOffCoordinates}
				setDropOffCoordinates={setDropOffCoordinates}
				estimatedUpperPrice={estimatedUpperPrice}
				setEstimatedUpperPrice={setEstimatedUpperPrice}
				currentStep={currentStep}
				setCurrentStep={setCurrentStep}
				setVisibleTerminalList={setVisibleTerminalList}
				setTerminalOpener={setTerminalOpener}
				setOpenBidModal={setOpenBidModal}
				saveDraft={saveDraft}
			/>

			<PlaceBidModal
				title="Place Your Bid"
				description="We will notifiy you through email for every carriers' bid."
				visible={openBidModal}
				data={data}
				setData={setData}
				closeModal={() => {
					setVisible(true);
					setVisible2(false);
					setOpenBidModal(false);
				}}
				handleSubmit={() => {
					if (!data?.bid_price) {
						return;
					}
					setVisible(false);
					setVisible2(true);
					setOpenBidModal(false);
				}}
			/>

			<RequirementFormModal
				visible={visible2}
				setOpenBidModal={setOpenBidModal}
				closeModal={closeModal2}
				setVisible={setVisible}
				setVisible2={setVisible2}
				data={data}
				handleCreateTrip={handleCreateTrip}
				loading={props.create_trip_loading}
			/>

			<SearchingDriverModal
				visible={visible3}
				loading={searchingDriver}
				setRefreshed={setRefreshed}
				refreshed={refreshed}
			/>
			<UnpaidInvoiceModal
				visible={unpaidInvoiceVisible}
				closeModal={() => setUnpaidInvoiceVisible(false)}
				setUnpaidInvoiceVisible={setUnpaidInvoiceVisible}
				openCardModal={() => setVisiblePayment(true)}
				cards={cards}
			/>
			<VerificationModal
				visible={verificationVisible}
				closeModal={() => setVerificationVisible(false)}
				openVerifiedModal={() => setVerifiedVisible(true)}
			/>
			<VerifiedModal
				visible={verifiedVisible}
				closeModal={() => setVerifiedVisible(false)}
				setTriggerNext={setTriggerNext}
			/>

			<CardModal
				visible={visiblePayment}
				closeModal={() => setVisiblePayment(false)}
				skipModal={handleCard}
				handleCard={handleCard}
				register={!visible2 && !unpaidInvoiceVisible} //hide skip button if card modal is triggered from requirement modal or unpaid invoice modal
			/>
			<ProfileInfoModal
				visible={visibleProfile}
				handleBack={goBackToAddCard}
				data={user}
				profileNext={profileNext}
				listType="picture-card"
				button={false}
				cardsLength={cards?.length}
			/>
			<LicenseModal
				visible={visibleLicense}
				setVisibleLicense={setVisibleLicense}
				setVerificationModal={setVisible}
			/>
			<TerminalList
				visible={visibleTerminalList}
				closeModal={() => setVisibleTerminalList(false)}
				setVisibleTerminalList={setVisibleTerminalList}
				setAddress={terminalOpener === "start" ? setStartAddress : setEndAddress}
				setCoordinates={
					terminalOpener === "start" ? setStartCoordinates : setEndCoordinates
				}
			/>
			<ReviewTripModal
				visible={reviewTrip}
				closeModal={closeReviewTripModal}
				data={props.unrated_trip}
			/>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	height: 90vh;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	//position: absolute;
	//bottom: 100px;

	width: 100%;
	height: 90vh;
	padding-bottom: 50px;

	button {
		width: 200px;
		margin: 0 auto;
		height: 50px;
		border-radius: 5px;
		font-weight: 500;
	}
`;

const mapStateToProps = ({
	user,
	create_trip_loading,
	create_bid_loading,
	unpaid_trips,
	unrated_trip,
}) => ({
	user,
	create_trip_loading,
	create_bid_loading,
	unpaid_trips,
	unrated_trip,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(RequestTrip);
